.tool-bar-wrap {
  margin: 0 auto;
  position: absolute;
  bottom: 25px;
  left: 50%;
  height: 59px;
  width: 80%;
  user-select: none;
  background-color: #fffffe;
  border-radius: 10px;
  box-shadow: 0 0 1px 0 #e2e2e2;
  transform: translateX(-50%);
  overflow: auto;
  box-sizing: border-box;
  scrollbar-width: thin;
}
.tool-bar {
  height: 100%;
  touch-action: pan-x;
  width: 1400px;
  display: flow-root;
  white-space: nowrap;
}
.tool-bar > div {
  margin: 0 10px;
  float: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.tool-bar-wrap::-webkit-scrollbar {
  height: 5px;
}
.tool-bar-wrap::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #adb5bd;
  border-top: 2px solid transparent;
}
.tool-bar-wrap::-webkit-scrollbar-track {
  width: 8px;
  border-radius: 8px;
  background: none;
}

.tool-bar-item {
  margin: 0 10px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  /*vertical-align: bottom;*/
}
