.export-item {
  display: flow-root;
}

.name-input {
  color: #716040;
  width: 70%;
  padding: 0 8px;
  outline: none;
  height: 25px;
  font-size: 16px;
  box-sizing: border-box;
  border: #eeeeee solid 1px;
  vertical-align: text-bottom;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #c6c6c6;
}
.name-input:focus {
  outline: none;
  border-color: #2177d5;
  /*border: #0366d6 solid 1px;*/
  box-shadow: 0 0 2px 1px #227bdb;
}

.export-item-name {
  float: left;
  color: #716040;
}
.export-btn {
  float: right;
  font-size: 17px;
  color: #f25042;
  cursor: pointer;
  margin-left: 10px;
}
.export-btn:hover {
  color: #ff7467;
}
.save-tips {
  height: 18px;
  color: #fc5a5a;
  text-align: center;
  transform: scaleY(0);
  transform-origin: top;
}
.save-tips[data-active="true"] {
  transform: scaleY(1);
  transition: transform ease-in-out 0.2s;
}
