.color-selector {
  height: 100%;
  overflow: hidden;
}
.color-selector > span {
  display: inline-block;
  margin: 0 10px;
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
