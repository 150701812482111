.props-selector {
  height: 100%;
  font-size: 17px;
  color: #020826;
  display: flow-root;
}

#range {
  cursor: pointer;
  width: 120px;
  vertical-align: text-bottom;
  -webkit-appearance: none;
}
#range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  border-radius: 5px;
  background-color: #eee;
}
#range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid #1ba1e2;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #fffffe;
}
input[type="range"]::-moz-range-track{
  height: 17px;
  background: #eee;
  border:none;
  border-radius: 4px;
}

input[type="range"]::-moz-range-thumb{
  width:16px;
  height:16px;
  border-radius: 8px;
  background-color: #fffffe;
  border: 2px solid #1ba1e2;
}
#checkbox {
  display: none;
}

.input-emoji {
  padding: 0 5px;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  width: 38px;
  height: 20px;
  position: relative;
  background-color: #cce4f5;
  vertical-align: bottom;
  border-radius: 12px;
  box-sizing: border-box;
  transition: background-color ease-in-out 0.15s;
}
.input-tips {
  line-height: 12px;
  width: 50%;
  color: #020826;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.input-tips:nth-child(2) {
  right: 0;
  color: #fffffe;
}
.input-emoji::after {
  content: "😛";
  display: inline-block;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: left ease-in-out 0.15s;
}
.input-fill > #checkbox:checked + .input-emoji {
  background-color: #bfd5e7;
}
.input-fill > #checkbox:checked + .input-emoji::after {
  content: "😄";
  left: 17px;
}
